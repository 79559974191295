.container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


.starterMain {
    /* background: url('/src/resources/videos/giphy.gif.mp4'); */
    width: 100%;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.videoWrapper {
    background: linear-gradient(180deg, rgba(2, 0, 98, 0.45) 4.01%, rgba(1, 13, 80, 0.47) 4.02%, rgba(24, 68, 108, 0.374569) 27.51%, rgba(0, 0, 0, 0) 100%);
}

.backgroundVideo {
    /* min-height: 100%; */
    width: 100%;
    margin-top: -550px;
    z-index: -1;
    position: absolute;
}

.headLogoWrapper {
    width: 100%;
    height: 147px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.headLogo {
    width: 152px;
    height: 147px;
}

.starterMainBlock {
    width: 100%;
    height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.mainWrapper {
    width: 700px;
    height: 100px;
    color: white;
}

.mainTitleText {
    font-size: 91px;
    line-height: 88.8px;
    font-family: 'Proxima Soft';
    font-weight: 700;
}

.mainDescText {
    font-size: 16px;
    line-height: 16px;
    font-family: 'Proxima Soft';
    font-weight: 400;
    margin-top: 26px;
}

.lowerWrapper {
    width: 100%;
    height: 153px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
    justify-content: space-between;
}

.scrollText {
    color: white;
    font-family: 'Proxima Soft';
    font-weight: 400;
}

.tripplePr {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.trippleFr {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-left: 30px;
}



$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .videoWrapper {
        width: 100vw;
        height: 100vh;
        margin-top: 0px;
        z-index: -1;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .backgroundVideo {
        width: 200%;
        height: 100%;
        left: -19px;
        margin-top: -65px;
        // position: absolute;
    }

    .headLogoWrapper {
        height: 120px;
    }

    .headLogo {
        height: 120px;
    }

    .mainWrapper {
        width: 100%;
        height: auto;
        padding: 10px;
    }

    .mainTitleText {
        font-size: 70px;
        line-height: 65px;
    }

    .mainDescText {
        font-size: 18px;
    }

    .trippleFr {
        display: none;
    }

    .tripplePr {
        width: 100%;
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .videoWrapper {
        width: 100vw;
        height: 100vh;
        margin-top: 0px;
        z-index: -1;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .backgroundVideo {
        width: 200%;
        height: auto;
        left: -19px;
        margin-top: -65px;
        // position: absolute;
    }

    .headLogoWrapper {
        height: 120px;
    }

    .headLogo {
        height: 120px;
    }

    .mainWrapper {
        width: 100%;
        height: auto;
        padding: 10px;
    }

    .mainTitleText {
        font-size: 70px;
        line-height: 65px;
    }

    .mainDescText {
        font-size: 18px;
    }

    .trippleFr {
        display: none;
    }

    .tripplePr {
        width: 100%;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .videoWrapper {
        width: 100vw;
        height: 100vh;
        margin-top: 0px;
        z-index: -1;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    .backgroundVideo {
        width: 140%;
        height: auto;
        left: -19px;
        margin-top: -65px;
        // position: absolute;
    }

    .headLogoWrapper {
        height: 120px;
    }

    .headLogo {
        height: 120px;
    }

    .mainWrapper {
        width: 100%;
        height: auto;
        padding: 30px;
    }

    .mainTitleText {
        font-size: 130px;
        line-height: 110px;
    }

    .mainDescText {
        font-size: 26px;
        line-height: 26px;
        max-width: 65%;
    }

    .trippleFr {
        display: none;
    }

    .tripplePr {
        width: 100%;
    }
}
