.container {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
    margin: 0;
}

.GameMain {
    width: 1384px;
    height: 660px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 25px;
}

.GameMainM {
    display: none;
    width: 100%;
    height: 100%;
    // display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 25px;
}

.gameBackImg {
    height: 100%;
    position: absolute;
    z-index: -1;
}

.GameDevBlock {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.GameDevStackBlock {
    width: 221px;
    height: 100%;
    position: relative;
    left: -140px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    grid-gap: 95px;
}

.GameDevMainBlock {
    width: 663px;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.GameDevTitleText {
    font-family: "Proxima Soft";
    font-weight: 700;
    font-size: 160px;
    color: #FCFCFC;
    position: relative;
    top: 215px;
    left: 400px;
}

.gameDevGamepad {
    z-index: 10;
    position: relative;
    top: -18px;
    left: -120px;
}



$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .container {
        height: 100vh;
        max-height: 560px;
        padding: 0;
    }

    .GameMain {
        display: none;
    }

    .GameMainM {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .gameStackImgM {
        width: 100vw;
        z-index: 10;
    }

    .gameMainImgM {
        position: absolute;
        left: 10px;
        margin-top: 310px;
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .container {
        height: 100vh;
        max-height: 560px;
        padding: 0;
    }

    .GameMain {
        display: none;
    }

    .GameMainM {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .gameStackImgM {
        width: 100vw;
        z-index: 10;
    }

    .gameMainImgM {
        position: absolute;
        left: 10px;
        margin-top: 310px;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .container {
        height: 100vh;
        max-height: 560px;
        padding: 0;
    }

    .GameMain {
        display: none;
    }

    .GameMainM {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }

    .gameStackImgM {
        width: 100vw;
        z-index: 10;
    }

    .gameMainImgM {
        position: absolute;
        left: 10px;
        margin-top: 310px;
    }
}
