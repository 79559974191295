.container {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
    margin: 100px 0;
}

.GoMain {
    width: 1384px;
    height: 678px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.goTextBlock {
    width: 800px;
    height: 100%;
    padding-left: 35px;
}

.goTextTitle {
    width: 800px;
    height: 120px;
    font-family: 'Proxima Soft';
    font-weight: 700;
    font-size: 64px;
    line-height: 59.2px;
    -webkit-text-stroke: 1px #fff;
}

.goTextSTitile {
    font-family: 'Proxima Soft';
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #FCFCFC;
    margin-top: 50px;
}

.goTextDesc {
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #BDBCC9;
    width: 385px;
    margin-top: 110px;
}




.goImgBlock {
    position: relative;
    left: -230px;
}





$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .GoMain {
        width: 100%;
        height: auto;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 4px;
    }

    .container {
        overflow: hidden;
        height: auto;
        min-height: 100vh;
    }

    .goTextBlock {
        width: 100%;
        height: auto;
        padding: 0;
    }

    .goTextTitle {
        width: 100%;
        height: auto;
        font-family: "Proxima Soft";
        font-weight: 700;
        font-size: 47px;
        line-height: 43.2px;
        -webkit-text-stroke: 1px #fff;
    }

    .goTextSTitile {
        font-weight: 500;
        font-size: 24px;
        margin-top: 25px;
        color: #BDBCC9;
    }

    .goTextDesc {
        font-family: "Proxima Soft";
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #BDBCC9;
        width: 100%;
        margin-top: 50px;
    }

    .goImgBlock {
        left: 0;
        height: auto;
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: center;
        // overflow: hidden;
        margin-top: 10px;

        img {
            width: 140%;
        }
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .GoMain {
        width: 100%;
        height: auto;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 4px;
    }

    .container {
        overflow: hidden;
        height: auto;
        min-height: 100vh;
    }

    .goTextBlock {
        width: 100%;
        height: auto;
        padding: 0;
    }

    .goTextTitle {
        width: 100%;
        height: auto;
        font-family: "Proxima Soft";
        font-weight: 700;
        font-size: 47px;
        line-height: 43.2px;
        -webkit-text-stroke: 1px #fff;
    }

    .goTextSTitile {
        font-weight: 500;
        font-size: 24px;
        margin-top: 25px;
        color: #BDBCC9;
    }

    .goTextDesc {
        font-family: "Proxima Soft";
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #BDBCC9;
        width: 100%;
        margin-top: 50px;
    }

    .goImgBlock {
        left: 0;
        height: auto;
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: center;
        // overflow: hidden;
        margin-top: 10px;

        img {
            width: 140%;
        }
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .GoMain {
        width: 100%;
        height: auto;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
    }

    .container {
        overflow: hidden;
        height: auto;
        min-height: 100vh;
    }

    .goTextBlock {
        width: 100%;
        height: auto;
        padding: 0;
    }

    .goTextTitle {
        width: 100%;
        height: auto;
        font-family: "Proxima Soft";
        font-weight: 700;
        font-size: 85px;
        line-height: 78.2px;
        -webkit-text-stroke: 1px #fff;
    }

    .goTextSTitile {
        font-weight: 500;
        font-size: 40px;
        line-height: 40px;
        margin-top: 25px;
        color: #BDBCC9;
    }

    .goTextDesc {
        font-family: "Proxima Soft";
        font-weight: 400;
        font-size: 23px;
        line-height: 23px;
        color: #BDBCC9;
        width: 100%;
        margin-top: 50px;
    }

    .goImgBlock {
        left: 0;
        height: auto;
        display: flex;
        width: 100vw;
        align-items: center;
        justify-content: center;
        // overflow: hidden;
        margin-top: 10px;

        img {
            width: 140%;
        }
    }
}
