.BusinesMain {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.container {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
    margin: 100px 0;
  }

.businesLeftBlock {
    width: 630px;
    height: 595px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.businesTitleBlock {
    width: 100%;
    height: auto;
    min-height: 197px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.businesTitleText {
    width: 100%;
    height: auto;
    min-height: 59px;
    font-family: 'Proxima Soft';
    font-weight: 700;
    font-size: 61px;
    line-height: 59.2px;
    color: #FCFCFC;
}

.businesTitleDesc {
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #BDBCC9;
    padding: 10px 0;
}

.businesBtnsBlock {
    width: auto;
    height: 34px;
    grid-gap: 32px;
    display: flex;
    align-items: center;
}

.businesBtn {
    width: auto;
    height: 34px;
    border-radius: 42px;
    border: 1px solid #BDBCC9;
    padding: 4px 32px;
    display: flex;
    grid-gap: 10px;
    cursor: pointer;
}

.businesBtnText {
    font-family: 'Proxima Soft';
    font-weight: 600;
    font-size: 16px;
    line-height: 25.6px;
    color: #BDBCC9;
}

.businesBtnCurrent {
    background-color: #767EEA;
    color: #03001E;
    border: 1px solid #767EEA;
    grid-gap: 10px;
    padding: 4px 32px;
    display: flex;
    width: auto;
    height: 34px;
    border-radius: 42px;
    cursor: pointer;
}

.businesDescriptionBlock {
    width: 404px;
    height: 224px;
}

.businesDescriptionTitle {
    font-size: 16px;
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #FCFCFC;
    margin-bottom: 15px;
}

.businesDescriptionDesc {
    width: 325px;
    height: 192px;
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #BDBCC9;
}

.businesDescriptionUl {
    list-style: none;
}

.businesDescriptionLi {
    padding: 10px 0;
    margin-left: 15px;
}

.businesMackBookBlock {
    width: 1020px;
    height: 676.07px;
    // top: 202px;
    // left: 727px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.businesMackBook {
    position: relative;
    width: 114%;
    top: 20px;
    transition: 1s;
}


@media (max-width: 1550px) {
    .businesMackBook {
        left: -12px;
    }

    .container {
        padding: 0 65px;
    }
}






$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .container {
        padding: 0;
        width: 100%;
        height: auto;
        min-height: 100vh;
    }

    .BusinesMain {
        padding: 0 4px;
        height: 100%;
    }

    .businesLeftBlock {
        width: 100%;
        height: 100vh;
    }

    .businesTitleBlock {
        grid-gap: 12px;
        padding: 0 10px;
    }

    .businesDescriptionTitle {
        font-size: 24px;
    }

    .businesBtnsBlock {
        grid-gap: 10px;
    }

    .businesMackBookBlock {
        width: 80%;
        /* height: 676.07px; */
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: -1;
    }

    .businesMackBook {
        left: 0;
    }

    .busDescM {
        margin-left: 10px;
    }

    .businesTitleText {
        width: 100%;
        height: auto;
        min-height: 59px;
        font-family: "Proxima Soft";
        font-weight: 700;
        font-size: 56px;
        line-height: 48.2px;
        color: #FCFCFC;
    }

    .businesTitleDesc {
        font-size: 14px;
        line-height: 14px;
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .container {
        padding: 0;
        width: 100%;
        height: auto;
        min-height: 100vh;
    }

    .BusinesMain {
        padding: 0 4px;
        height: 100%;
    }

    .businesLeftBlock {
        width: 100%;
        height: 100vh;
    }

    .businesTitleBlock {
        grid-gap: 12px;
        padding: 0 10px;
    }

    .businesDescriptionTitle {
        font-size: 24px;
    }

    .businesBtnsBlock {
        grid-gap: 10px;
    }

    .businesMackBookBlock {
        width: 80%;
        /* height: 676.07px; */
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: -1;
    }

    .businesMackBook {
        left: 0;
    }

    .busDescM {
        margin-left: 10px;
    }

    .businesTitleText {
        width: 100%;
        height: auto;
        min-height: 59px;
        font-family: "Proxima Soft";
        font-weight: 700;
        font-size: 56px;
        line-height: 48.2px;
        color: #FCFCFC;
    }

    .businesTitleDesc {
        font-size: 14px;
        line-height: 14px;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .container {
        padding: 0;
        width: 100%;
        height: auto;
        min-height: 100vh;
    }

    .BusinesMain {
        padding: 0 30px;
        height: 100%;
    }

    .businesLeftBlock {
        width: 100%;
        height: 1060px;
    }

    .businesTitleBlock {
        grid-gap: 20px;
        padding: 0;
    }

    .businesDescriptionTitle {
        font-size: 24px;
    }

    .businesBtnsBlock {
        grid-gap: 10px;
    }

    .businesBtnText {
        font-size: 23px;
    }

    .businesBtn {
        width: auto;
        height: 50px;
        border-radius: 42px;
        border: 1px solid #BDBCC9;
        padding: 12px 40px;
        display: flex;
        grid-gap: 10px;
        cursor: pointer;
    }

    .businesBtnCurrent {
        height: 50px;
        padding: 12px 40px;
    }

    .businesMackBookBlock {
        width: 80%;
        /* height: 676.07px; */
        margin-top: 170px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: -1;
    }

    .businesMackBook {
        left: 0;
    }

    .busDescM {
        margin-left: 0px;
    }

    .businesDescriptionTitle {
        font-size: 35px;
        margin-bottom: 18px;
    }

    .businesDescriptionDesc {
        width: 425px;
        height: 220px;
        font-family: "Proxima Soft";
        font-weight: 400;
        font-size: 23px;
        line-height: 23px;
        color: #BDBCC9;
    }

    .businesTitleText {
        width: 100%;
        height: auto;
        min-height: 59px;
        font-family: "Proxima Soft";
        font-weight: 700;
        font-size: 65px;
        line-height: 48.2px;
        color: #FCFCFC;
    }

    .businesTitleDesc {
        font-size: 22px;
        line-height: 22px;
    }
}
