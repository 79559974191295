.container {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
    margin: 100px 0;
}

.FAMain {
    width: 1384px;
    margin-top: 200px;
    height: 660px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 25px;
}

.bbRight {
    margin-top: 150px;
}

.bbLeft {
    margin-top: -150px;
}

.bbBlock {
    position: absolute;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        position: relative;
        width: 40%;
    }
}

.FillAppBlock {
    width: 1000px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    z-index: 10;

    img {
        width: 650px;
        position: relative;
        top: -100px;
    }

    form {
        width: 600px;
        height: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 30px;
        z-index: 20;
        top: 135px;
        left: -140px;

        button {
            width: 100%;
            border-radius: 70px;
            padding: 16px 32px 16px 32px;
            // display: flex;
            grid-gap: 10px;
            background-color: #FCFCFC;
            font-size: 'Proxima Soft';
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            transition: .5s;

            &:hover {
                cursor: pointer;
                background-color: #a7e2ff;
            }
        }
    }
}

.inputField {
    width: 100%;
    padding: 16px 25px 16px 25px;
    border: 1px solid #D9D9D9;
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #D9D9D9;
    border-radius: 25px;
    background: none;
}





$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .container {
        padding: 0;
        margin: 0;
        align-items: flex-start;
    }

    .FAMain {
        margin-top: 0;
    }

    .FillAppBlock {
        flex-direction: column;
        width: 100%;
        height: auto;
        align-items: center;
        overflow: hidden;

        img {
            width: 140%;
            height: auto;
            top: auto;
        }

        form {
            width: 100%;
            left: auto;
            top: auto;
        }
    }

    .bbBlock {
        img {
            width: 50%;
        }
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .container {
        padding: 0;
        margin: 0;
        align-items: flex-start;
    }

    .FAMain {
        margin-top: 0;
    }

    .FillAppBlock {
        flex-direction: column;
        width: 100%;
        height: auto;
        align-items: center;
        overflow: hidden;

        img {
            width: 140%;
            height: auto;
            top: auto;
        }

        form {
            width: 100%;
            left: auto;
            top: auto;
        }
    }

    .bbBlock {
        img {
            width: 50%;
        }
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .container {
        padding: 0;
        margin: 0;
        align-items: flex-start;
    }

    .FAMain {
        margin-top: 0;
    }

    .FillAppBlock {
        flex-direction: column;
        width: 100%;
        height: auto;
        align-items: center;
        overflow: hidden;

        img {
            width: 140%;
            height: auto;
            top: auto;
        }

        form {
            width: 100%;
            left: auto;
            top: auto;
        }
    }

    .bbBlock {
        img {
            width: 50%;
        }
    }
}
