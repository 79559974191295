.container {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
    margin: 100px 0;
}

.TechMain {
    width: 1384px;
    height: 370px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.techTitle {
    width: auto;
    height: 100px;
    font-size: 105px;
    -webkit-text-stroke: 1px #9d9d9d;
    line-height: 90px;
    font-family: 'Proxima Soft';
    font-weight: 700;
}

.techBlock {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.techTopStack {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 40px;
}

.techBotStack {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 50px;
    margin-top: 45px;
}





$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .TechMain {
        width: 100%;
        /* height: 370px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }

    .container {
        margin: -100px 0;
    }

    .techTitle {
        font-size: 55px;
    }

    .techBlock {
        height: auto;
    }

    .techTopStack,
    .techBotStack {
        flex-wrap: wrap;
        grid-gap: 15px;
    }

    .techBotStack {
        margin-top: 15px;
    }

    .mobileInvis {
        display: none;
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .TechMain {
        width: 100%;
        /* height: 370px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }

    .container {
        margin: -100px 0;
    }

    .techTitle {
        font-size: 55px;
    }

    .techBlock {
        height: auto;
    }

    .techTopStack,
    .techBotStack {
        flex-wrap: wrap;
        grid-gap: 15px;
    }

    .techBotStack {
        margin-top: 15px;
    }

    .mobileInvis {
        display: none;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .TechMain {
        width: 100%;
        /* height: 370px; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }

    .container {
        margin: -100px 0;
    }

    .techTitle {
        font-size: 105px;
    }

    .techBlock {
        height: auto;
        margin-top: 45px;
    }

    .techTopStack,
    .techBotStack {
        width: 450px;
        flex-wrap: wrap;
        grid-gap: 35px;

        img {
            width: 115px;
        }
    }

    .techBotStack {
        margin-top: 15px;
    }

    .mobileInvis {
        display: none;
    }
}
