.container {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
    margin: 100px 0;
}

.MobileMain {
    width: 1384px;
    height: 660px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 25px;
}

.mobileTitleBlock {
    width: 461px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.mobileTitleTextBlock {
    width: 100%;
    height: auto;
}

.mobileTitleText {
    font-family: 'Proxima Soft';
    font-weight: 700;
    font-size: 64px;
    line-height: 59.2px;
    color: #FCFCFC;
}

.mobileTitleDesc {
    width: 100%;
    height: auto;
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #BDBCC9;
    margin-top: 20px;
}

.mobileBtnsBottomBlock {
    width: auto;
    height: 35px;
    display: flex;
    align-items: center;
    grid-gap: 24px;
}

.mobileBtn {
    width: auto;
    height: 34px;
    border-radius: 42px;
    border: 1px solid #BDBCC9;
    padding: 4px 32px;
    display: flex;
    grid-gap: 10px;
    cursor: pointer;
    font-family: 'Proxima Soft';
    font-weight: 600;
    font-size: 16px;
    line-height: 25.6px;
    color: #BDBCC9;
}

.mobileBtnActive {
    background-color: #767EEA;
    color: #03001E;
    border: 1px solid #767EEA;
    grid-gap: 10px;
    padding: 4px 32px;
    display: flex;
    width: auto;
    height: 34px;
    border-radius: 42px;
    cursor: pointer;
    font-family: 'Proxima Soft';
    font-weight: 600;
    font-size: 16px;
    line-height: 25.6px;
    color: #03001E;
}

.mobileIphoneImgBlock {
    width: 390px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileIphoneImgBlockMActive {
    display: none;
}

.mobileIphoneImg {
    height: 100%;
}

.mobileDescBlock {
    width: 440px;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.mobileDescText {
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #BDBCC9;
}






$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .MobileMain {
        flex-direction: column;
        width: 100vw;
        margin-top: 200px;
    }

    .container {
        height: auto;
        min-height: 100vh;
    }

    .mobileTitleBlock {
        width: 100%;

    }

    .mobileTitleText {
        font-size: 50px;
        line-height: 50px;
    }

    .mobileBtnsBottomBlock {
        width: 100%;
        margin-top: 30px;
    }

    .mobileDescBlock {
        width: 100%;
    }

    .mobileIphoneImgBlock {
        display: none;
    }

    .mobileIphoneImgBlockMActive {
        width: 100%;
        margin: 90px 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mobileIphoneImg {
        height: auto;
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .MobileMain {
        flex-direction: column;
        width: 100vw;
        margin-top: 200px;
    }

    .container {
        height: auto;
        min-height: 100vh;
    }

    .mobileTitleBlock {
        width: 100%;

    }

    .mobileTitleText {
        font-size: 50px;
        line-height: 50px;
    }

    .mobileBtnsBottomBlock {
        width: 100%;
        margin-top: 30px;
    }

    .mobileDescBlock {
        width: 100%;
    }

    .mobileIphoneImgBlock {
        display: none;
    }

    .mobileIphoneImgBlockMActive {
        width: 100%;
        margin: 90px 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mobileIphoneImg {
        height: auto;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .MobileMain {
        flex-direction: column;
        width: 100vw;
        margin-top: 200px;
    }

    .container {
        height: auto;
        min-height: 100vh;
    }

    .mobileTitleBlock {
        width: 100%;

    }

    .mobileTitleText {
        font-size: 75px;
        line-height: 75px;
    }

    .mobileTitleDesc {
        font-size: 21px;
        line-height: 21px;
    }

    .mobileBtnsBottomBlock {
        width: 100%;
        margin-top: 30px;
    }

    .mobileBtn {
        width: auto;
        height: 50px;
        border-radius: 42px;
        border: 1px solid #BDBCC9;
        padding: 12px 40px;
        display: flex;
        grid-gap: 10px;
        cursor: pointer;
        font-size: 23px;
    }

    .mobileBtnActive {
        height: 50px;
        padding: 12px 40px;
        font-size: 23px;
    }

    .mobileDescBlock {
        width: 100%;
    }

    .mobileIphoneImgBlock {
        display: none;
    }

    .mobileIphoneImgBlockMActive {
        width: 100%;
        margin: 90px 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mobileIphoneImg {
        height: auto;
    }

    .mobileDescText {
        font-size: 21px;
        line-height: 21px;
    }
}



