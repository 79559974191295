@charset "UTF-8";
/*--------------------------*/
/* Подключение шрифта Proxima Soft */
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-SemiBold.eot");
  src: local("Proxima Soft SemiBold"), local("ProximaSoft-SemiBold"), url("../resources/fonts/Proxima Soft/ProximaSoft-SemiBold.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-SemiBold.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-SemiBold.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-BoldIt.eot");
  src: local("Proxima Soft Bold Italic"), local("ProximaSoft-BoldIt"), url("../resources/fonts/Proxima Soft/ProximaSoft-BoldIt.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-BoldIt.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-BoldIt.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-BoldIt.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-Light.eot");
  src: local("Proxima Soft Light"), local("ProximaSoft-Light"), url("../resources/fonts/Proxima Soft/ProximaSoft-Light.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-Light.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-Light.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-Thin.eot");
  src: local("Proxima Soft Thin"), local("ProximaSoft-Thin"), url("../resources/fonts/Proxima Soft/ProximaSoft-Thin.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-Thin.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-Thin.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-LightIt.eot");
  src: local("Proxima Soft Light Italic"), local("ProximaSoft-LightIt"), url("../resources/fonts/Proxima Soft/ProximaSoft-LightIt.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-LightIt.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-LightIt.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-LightIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-Medium.eot");
  src: local("Proxima Soft Medium"), local("ProximaSoft-Medium"), url("../resources/fonts/Proxima Soft/ProximaSoft-Medium.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-Medium.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-Medium.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-SemiBoldIt.eot");
  src: local("Proxima Soft SemiBold Italic"), local("ProximaSoft-SemiBoldIt"), url("../resources/fonts/Proxima Soft/ProximaSoft-SemiBoldIt.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-SemiBoldIt.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-SemiBoldIt.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-SemiBoldIt.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-Bold.eot");
  src: local("Proxima Soft Bold"), local("ProximaSoft-Bold"), url("../resources/fonts/Proxima Soft/ProximaSoft-Bold.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-Bold.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-Bold.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-Black.eot");
  src: local("Proxima Soft Black"), local("ProximaSoft-Black"), url("../resources/fonts/Proxima Soft/ProximaSoft-Black.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-Black.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-Black.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-RegularIt.eot");
  src: local("Proxima Soft Regular Italic"), local("ProximaSoft-RegularIt"), url("../resources/fonts/Proxima Soft/ProximaSoft-RegularIt.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-RegularIt.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-RegularIt.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-RegularIt.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-Regular.eot");
  src: local("Proxima Soft Regular"), local("ProximaSoft-Regular"), url("../resources/fonts/Proxima Soft/ProximaSoft-Regular.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-Regular.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-Regular.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-MediumIt.eot");
  src: local("Proxima Soft Medium Italic"), local("ProximaSoft-MediumIt"), url("../resources/fonts/Proxima Soft/ProximaSoft-MediumIt.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-MediumIt.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-MediumIt.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-MediumIt.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-BlackIt.eot");
  src: local("Proxima Soft Black Italic"), local("ProximaSoft-BlackIt"), url("../resources/fonts/Proxima Soft/ProximaSoft-BlackIt.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-BlackIt.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-BlackIt.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-BlackIt.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-ExtraBoldIt.eot");
  src: local("Proxima Soft ExtraBold Italic"), local("ProximaSoft-ExtraBoldIt"), url("../resources/fonts/Proxima Soft/ProximaSoft-ExtraBoldIt.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-ExtraBoldIt.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-ExtraBoldIt.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-ExtraBoldIt.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-ThinIt.eot");
  src: local("Proxima Soft Thin Italic"), local("ProximaSoft-ThinIt"), url("../resources/fonts/Proxima Soft/ProximaSoft-ThinIt.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-ThinIt.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-ThinIt.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-ThinIt.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Proxima Soft";
  src: url("../resources/fonts/Proxima Soft/ProximaSoft-ExtraBold.eot");
  src: local("Proxima Soft ExtraBold"), local("ProximaSoft-ExtraBold"), url("../resources/fonts/Proxima Soft/ProximaSoft-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../resources/fonts/Proxima Soft/ProximaSoft-ExtraBold.woff2") format("woff2"), url("../resources/fonts/Proxima Soft/ProximaSoft-ExtraBold.woff") format("woff"), url("../resources/fonts/Proxima Soft/ProximaSoft-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
::root {
  --black: #000;
}


body {
  background-color: #030509;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#root {
  width: 100vw;
  min-height: 100vh;
  height: auto;
}