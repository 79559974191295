.container {
    width: auto;
    display: flex;
    justify-content: center;
    margin-top: 200px;
    align-items: flex-end;
    /* height: 100vh; */
    padding: 10px;
}

.FooterMain {
    width: 1384px;
    height: 415px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0px 25px;
}

.FooterTitle {
    font-family: 'Proxima Soft';
    font-weight: 700;
    font-size: 96px;
    line-height: 88.8px;
    color: #FCFCFC;
}

.FooterMainBlock {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    div {
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 8px;
    }
}


.FooterContentElTitle {
    font-family: 'Proxima Soft';
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    color: #FCFCFC;
}

.FooterContentElDesc {
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 18px;
    line-height: 28.8px;
    color: #6A6971;
    transition: .5s;
    text-decoration: none;

    &:hover {
        color: #FCFCFC;
        cursor: pointer;
    }
}






$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .FooterMainBlock {
        flex-direction: column;
        padding: 100px 20px 50px 20px;
    }

    .FooterTitle {
        font-size: 80px;
        line-height: 80px;
    }

    .FooterMainBlock {
        justify-content: flex-start;
        grid-gap: 30px;
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .FooterMainBlock {
        flex-direction: column;
        padding: 100px 20px 50px 20px;
    }

    .FooterTitle {
        font-size: 80px;
        line-height: 80px;
    }

    .FooterMainBlock {
        justify-content: flex-start;
        grid-gap: 30px;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .FooterMainBlock {
        flex-direction: column;
        padding: 100px 20px 50px 20px;
    }

    .FooterTitle {
        font-size: 80px;
        line-height: 80px;
    }

    .FooterMainBlock {
        justify-content: flex-start;
        grid-gap: 30px;
    }
}
