.AppContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .container {
    width: auto;
    max-width: 1920px;
    display: flex;
    justify-content: center;
  }
  