.darkBG {
    background-color: rgba(78, 78, 78, 0.2);
    backdrop-filter: blur(10px);
    width: 100vw;
    height: 100vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 100;
  }
  
  .centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
  }
  
  .modal {
    width: 900px;
    height: 500px;
    background: black;
    z-index: 10;
    border-radius: 7px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    padding: 50px;
  }
  
  .modalWindowWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

.modalHeader {
    width: 100%;
    height: auto;   
}

.HeaderTitleText {
    font-family: 'Proxima Soft';
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    color: #FFFFFF;
}

.HeaderTitleDesc {
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 15px;
    line-height: 23.8px;
    margin-top: 35px;
    color: #FFFFFF;
}

.ModalForm {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
} 

.ModalFormsInputsBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 45%;
    height: 100%;
}

.inputField {
    width: 100%;
    padding: 16px 25px 16px 25px;
    border: 1px solid #D9D9D9;
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #D9D9D9;
    border-radius: 25px;
    background: none;
}

.ModalCloseInput {
    width: 100%;
    padding: 16px 32px 16px 32px;
    border: 1px solid #D9D9D9;
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 19px;
    line-height: 19px;
    color: #BDBCC9;
    border-radius: 25px;
    background: none;
    transition: .4s;

    &:hover {
        background-color: #303239;
        border: 1px solid #303239;
        cursor: pointer;
    }
}

.ModalSendFill {
    width: 100%;
    padding: 16px 32px 16px 32px;
    border: 1px solid #FCFCFC;
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 19px;
    line-height: 19px;
    color: #060040;
    border-radius: 25px;
    background: #FCFCFC;
    transition: .4s;

    &:hover {
        background-color: #a7e2ff;
        border: 1px solid #a7e2ff;
        cursor: pointer;
    }
}




$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .ModalForm {
        flex-direction: column;
    }

    .modal {
        width: 100%;
        height: auto;
    }

    .ModalFormsInputsBlock {
        width: 100%;
    }

    .inputField {
        margin: 10px 0 10px 0;
    }

    .ModalFormsInputsBlock {
        margin-top: 10px;
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .ModalForm {
        flex-direction: column;
    }

    .modal {
        width: 100%;
        height: auto;
    }

    .ModalFormsInputsBlock {
        width: 100%;
    }

    .inputField {
        margin: 10px 0 10px 0;
    }

    .ModalFormsInputsBlock {
        margin-top: 10px;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .ModalForm {
        flex-direction: column;
    }

    .modal {
        width: 100%;
        height: auto;
    }

    .ModalFormsInputsBlock {
        width: 100%;
    }

    .inputField {
        margin: 10px 0 10px 0;
    }

    .ModalFormsInputsBlock {
        margin-top: 10px;
    }
}



