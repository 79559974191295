.container {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
    margin: 100px 0;
}

.TDMain {
    width: 1384px;
    height: 660px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px 25px;
}

.tdTitileBlock {
    width: 400px;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
}

.tdTitleTopBlock {
    width: 100%;
    height: auto;
    z-index: 10;
}

.tdTitleText {
    font-family: 'Proxima Soft';
    font-weight: 700;
    font-size: 64px;
    line-height: 59.2px;
    color: #FCFCFC;
}

.tdTitleDescText {
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 18px;
    line-height: 28.8px;
    color: #BDBCC9;
}

.tdTitleBottomBlock {
    width: 100%;
    height: auto;
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 18px;
    line-height: 28.8px;
    color: #BDBCC9;
}

.slideWrapper {
    width: 370px;
    height: auto;
    max-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50px;
}

.tdButtonImg {
    position: absolute;
    left: 575px;
}

.tdButtonRoomImg {
    position: absolute;
    left: 740px;
}

.motoImg {
    position: relative;
    height: 550px;
    z-index: -10;
    left: -40px;
}

.charImg {
    position: relative;
    height: 620px;

    z-index: -10;
    max-height: 100%;
    left: -40px;
}

.roomImg {
    position: relative;
    height: 550px;
    z-index: -10;
    left: 160px;
}

.firstSwiperSlide {
    z-index: -10;
}

.tdNavBlock {
    width: 550px;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    z-index: 10;
}

.arrowsBlock {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 24px;
}

.prevArrow {
    transform: rotate(180deg);
}

.nextArrow {

}

.prevArrowEnd {
    transform: rotate(180deg);
    opacity: 0.3;
}

.nextArrowEnd {
    opacity: 0.3;
}


.tdBtnsBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 24px;
}

.tdBtn {
    width: auto;
    height: 34px;
    border-radius: 42px;
    border: 1px solid #BDBCC9;
    padding: 4px 32px;
    display: flex;
    grid-gap: 10px;
    cursor: pointer;
    font-family: 'Proxima Soft';
    font-weight: 600;
    font-size: 16px;
    line-height: 25.6px;
    color: #BDBCC9;
}

.tdBtnActive {
    background-color: #767EEA;
    color: #03001E;
    border: 1px solid #767EEA;
    grid-gap: 10px;
    padding: 4px 32px;
    display: flex;
    width: auto;
    height: 34px;
    border-radius: 42px;
    cursor: pointer;
    font-family: 'Proxima Soft';
    font-weight: 600;
    font-size: 16px;
    line-height: 25.6px;
    color: #03001E;
}

.FirstSwiper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 1370px;
    position: absolute !important;
    top: 0px;
    left: -405px;
}

.firstSwiperSlide {
    display: flex !important;
    align-items: center;
    justify-content: center;
    
}






$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .container {
        width: auto;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 10px;
        margin: 490px 0 0 0;
        min-height: 100vh;
    }

    .TDMain {
        flex-direction: column;
        width: 100vw;
        height: auto;
    }

    .tdTitileBlock {
        width: 100%;
        height: auto;

    }

    .tdTitleDescText {
        padding-top: 10px;
    }

    .tdTitleBottomBlock {
        display: none;
    }

    .slideWrapper {
        top: 75px;
        width: 100%;
    }

    .FirstSwiper {
        width: 100%;
        left: 0;
        margin-top: 20px;
        height: 500px;
    }

    .firstSwiperSlide {
        img {
            max-height: 90%;
        }
    }

    .tdNavBlock {
        width: 100%;
        height: 610px;
        flex-direction: column-reverse;
        padding-top: 20px;
    }

    .tdBtnsBlock {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .arrowsBlock {
        justify-content: center;
    }

    .motoImg {
        width: 100%;
        height: auto;
        left: 0;
    }

    .wiper-wrapper {
        display: flex !important;
        align-items: center !important;
    }

    .tdButtonImg {
        left: 85px;
    }

    .charImg {
        left: 0px;
    }

    .roomImg {
        width: 100%;
        left: 0px;
        height: auto;
    }

    .tdButtonRoomImg {
        left: 85px;
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .container {
        width: auto;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 10px;
        margin: 490px 0 0 0;
        min-height: 100vh;
    }

    .TDMain {
        flex-direction: column;
        width: 100vw;
        height: auto;
    }

    .tdTitileBlock {
        width: 100%;
        height: auto;

    }

    .tdTitleDescText {
        padding-top: 10px;
    }

    .tdTitleBottomBlock {
        display: none;
    }

    .slideWrapper {
        top: 75px;
        width: 100%;
    }

    .FirstSwiper {
        width: 100%;
        left: 0;
        margin-top: 20px;
        height: 500px;
    }

    .firstSwiperSlide {
        img {
            max-height: 90%;
        }
    }

    .tdNavBlock {
        width: 100%;
        height: 610px;
        flex-direction: column-reverse;
        padding-top: 20px;
    }

    .tdBtnsBlock {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .arrowsBlock {
        justify-content: center;
    }

    .motoImg {
        width: 100%;
        height: auto;
        left: 0;
    }

    .wiper-wrapper {
        display: flex !important;
        align-items: center !important;
    }

    .tdButtonImg {
        left: 85px;
    }

    .charImg {
        left: 0px;
    }

    .roomImg {
        width: 100%;
        left: 0px;
        height: auto;
    }

    .tdButtonRoomImg {
        left: 85px;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .container {
        width: auto;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 10px;
        margin: 490px 0 0 0;
        min-height: 100vh;
    }

    .TDMain {
        flex-direction: column;
        width: 100vw;
        height: auto;
    }

    .tdTitileBlock {
        width: 100%;
        height: auto;
    }

    .tdTitleText {
        font-size: 75px;
        line-height: 75.2px;
    }

    .tdTitleDescText {
        padding-top: 30px;
        font-size: 21px;
    }

    .tdTitleBottomBlock {
        display: none;
    }

    .slideWrapper {
        top: 75px;
        width: 100%;
    }

    .FirstSwiper {
        width: 100%;
        left: 0;
        margin-top: 20px;
        height: 500px;
    }

    .firstSwiperSlide {
        img {
            max-height: 90%;
        }
    }

    .tdNavBlock {
        width: 100%;
        height: 610px;
        flex-direction: column-reverse;
        padding-top: 20px;
        margin-top: 20px;
    }

    .tdBtnsBlock {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .tdBtn {
        width: auto;
        height: 50px;
        border-radius: 42px;
        border: 1px solid #BDBCC9;
        padding: 12px 40px;
        display: flex;
        grid-gap: 10px;
        cursor: pointer;
        font-size: 23px;
    }

    .tdBtnActive {
        height: 50px;
        padding: 12px 40px;
        font-size: 23px;
    }

    .arrowsBlock {
        justify-content: center;
    }

    .motoImg {
        width: 100%;
        height: auto;
        left: 0;
    }

    .wiper-wrapper {
        display: flex !important;
        align-items: center !important;
    }

    .tdButtonImg {
        left: 85px;
    }

    .charImg {
        left: 0px;
    }

    .roomImg {
        width: 100%;
        left: 0px;
        height: auto;
    }

    .tdButtonRoomImg {
        left: 85px;
    }
}
