.container {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
    margin: 100px 0 0 0;
}

.WebMain {
    width: 1384px;
    height: 660px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px 25px;
}

.WebTitleBlock {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.WebTitleText {
    font-family: 'Proxima Soft';
    font-weight: 700;
    font-size: 64px;
    line-height: 59.2px;
    color: #FCFCFC;
}

.WebArrowBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 24px;
    width: auto;
    height: auto;
}

.prevArrow {
    transform: rotate(180deg);
}

.WebSwiperWrapper {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.WebSwiper {
    width: 100%;
    height: auto;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.WebSlide {
    width: 100%;
    height: auto;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    grid-gap: 30px;
}

.WebSlideText {
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #BDBCC9;
}

.webIpadImg {
    width: 700px;
}




$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .WebMain {
        width: 100%;
        height: 100%;
    }

    .container {
        max-height: 650px;
    }

    .WebTitleBlock {
        flex-direction: column;
        height: 95%;
    }

    .WebTitleText {
        font-size: 45px;
        line-height: 45px;
    }

    .WebSwiperWrapper {
        width: 100%;
        margin-top: 160px;
        position: absolute;
    }

    .WebSlideText {
        padding: 0px 20px;
        text-align: center;
    }

    .webIpadImg {
        width: 95%;
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .WebMain {
        width: 100%;
        height: 100%;
    }

    .container {
        max-height: 650px;
    }

    .WebTitleBlock {
        flex-direction: column;
        height: 95%;
    }

    .WebTitleText {
        font-size: 45px;
        line-height: 45px;
    }

    .WebSwiperWrapper {
        width: 100%;
        margin-top: 160px;
        position: absolute;
    }

    .WebSlideText {
        padding: 0px 20px;
        text-align: center;
    }

    .webIpadImg {
        width: 95%;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .WebMain {
        width: 100%;
        height: 100%;
    }

    .container {
        max-height: 650px;
    }

    .WebTitleBlock {
        flex-direction: column;
        height: 95%;
    }

    .WebTitleText {
        font-size: 45px;
        line-height: 45px;
    }

    .WebSwiperWrapper {
        width: 100%;
        margin-top: 160px;
        position: absolute;
    }

    .WebSlideText {
        padding: 0px 20px;
        text-align: center;
    }

    .webIpadImg {
        width: 95%;
    }
}
