.container {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
    margin: 100px 0;
}

.MobileGameMain {
    width: 1384px;
    height: 660px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0px 25px;
}

.MobileGTitleBlock {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.MobileGTopTitle {
    width: auto;
    height: auto;
    font-family: "Proxima Soft";
    font-weight: 700;
    font-size: 100px;
    position: relative;
    color: #FCFCFC;
}

.MobileGRotatedGamepadImg {
    position: relative;
    top: -146px;
    left: 115px;
    width: 620px;
}

.MobileGMiddleBlock {
    height: 333px;
    position: relative;
    top: -120px;
}

.MobileGMiddleImg {
    position: relative;
    left: -30px;
    height: 190%;
}

.MobileGMessage {
    font-family: "Proxima Soft";
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    color: #020011;
    width: 180px;
    height: 50px;
    background-color: #FCFCFC;
    border-radius: 35px 35px 0 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: -222px;
    margin-top: 163px;
}

.MobileGBottomBlock {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.MobileGBottonTitle {
    width: auto;
    height: auto;
    font-family: "Proxima Soft";
    font-weight: 700;
    font-size: 100px;
    position: relative;
    color: #FCFCFC;
    left: -100px;
}

.MobileGBottomGamepad {
    position: relative;
    top: -118px;
    height: 157%;
    left: 63px;
}





$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .container {
        overflow: hidden;
    }

    .MobileGameMain {
        padding: 0;
        width: 100%;
        height: 100%;
    }

    .MobileGTitleBlock {
        justify-content: flex-start;
        padding-left: 10px;
        // overflow: hidden;
    }

    .MobileGRotatedGamepadImg {
        position: relative;
        width: 330px;
        top: auto;
        margin-left: -65px;
        left: auto;
    }

    .MobileGTopTitle {
        font-size: 75px;
        letter-spacing: -0.08em;
    }

    .MobileGMiddleImg {
        position: relative;
        left: -7px;
        top: 55px;
        height: 125%;
    }

    .MobileGMessage {
        font-family: "Proxima Soft";
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #020011;
        width: 102px;
        height: 33px;
        background-color: #FCFCFC;
        border-radius: 35px 35px 0 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        margin-left: 30px;
        margin-top: 53px;
    }

    .MobileGBottomGamepad {
        position: relative;
        top: -40px;
        height: 115%;
        left: -145px;
    }

    .MobileGBottonTitle {
        width: auto;
        height: auto;
        font-family: "Proxima Soft";
        font-weight: 700;
        font-size: 100px;
        position: relative;
        color: #FCFCFC;
        margin-top: 46px;
        left: -240px;
        letter-spacing: -0.08em;
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .container {
        overflow: hidden;
    }

    .MobileGameMain {
        padding: 0;
        width: 100%;
        height: 100%;
    }

    .MobileGTitleBlock {
        justify-content: flex-start;
        padding-left: 10px;
        // overflow: hidden;
    }

    .MobileGRotatedGamepadImg {
        position: relative;
        width: 330px;
        top: auto;
        margin-left: -65px;
        left: auto;
    }

    .MobileGTopTitle {
        font-size: 75px;
        letter-spacing: -0.08em;
    }

    .MobileGMiddleImg {
        position: relative;
        left: -7px;
        top: 55px;
        height: 125%;
    }

    .MobileGMessage {
        font-family: "Proxima Soft";
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #020011;
        width: 102px;
        height: 33px;
        background-color: #FCFCFC;
        border-radius: 35px 35px 0 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        margin-left: 30px;
        margin-top: 53px;
    }

    .MobileGBottomGamepad {
        position: relative;
        top: -40px;
        height: 115%;
        left: -145px;
    }

    .MobileGBottonTitle {
        width: auto;
        height: auto;
        font-family: "Proxima Soft";
        font-weight: 700;
        font-size: 100px;
        position: relative;
        color: #FCFCFC;
        margin-top: 46px;
        left: -240px;
        letter-spacing: -0.08em;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .container {
        overflow: hidden;
    }

    .MobileGameMain {
        padding: 0;
        width: 100%;
        height: 100%;
    }

    .MobileGTitleBlock {
        justify-content: flex-start;
        padding-left: 10px;
        // overflow: hidden;
    }

    .MobileGRotatedGamepadImg {
        position: relative;
        width: 330px;
        top: auto;
        margin-left: -65px;
        left: auto;
    }

    .MobileGTopTitle {
        font-size: 75px;
        letter-spacing: -0.08em;
    }

    .MobileGMiddleImg {
        position: relative;
        left: -7px;
        top: 55px;
        height: 125%;
    }

    .MobileGMessage {
        font-family: "Proxima Soft";
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #020011;
        width: 102px;
        height: 33px;
        background-color: #FCFCFC;
        border-radius: 35px 35px 0 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        margin-left: 30px;
        margin-top: 53px;
    }

    .MobileGBottomGamepad {
        position: relative;
        top: -40px;
        height: 115%;
        left: -145px;
    }

    .MobileGBottonTitle {
        width: auto;
        height: auto;
        font-family: "Proxima Soft";
        font-weight: 700;
        font-size: 100px;
        position: relative;
        color: #FCFCFC;
        margin-top: 46px;
        left: -240px;
        letter-spacing: -0.08em;
    }
}
