.casesMain {
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.casesLeftBlock, .casesRightBlock {
    width: 420px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.casesBlock {
    width: 1166px;
    height: 311px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.caseBlock {
    width: 404px;
    height: 80px;
}

.caseTitle {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.caseIco {
    width: 32px;
    height: 32px;
    color: white;
}

.caseTitleText {
    height: 24px;
    font-family: 'Proxima Soft';
    font-weight: 600;
    font-size: 31px;
    line-height: 31px;
    color: white;
    padding-left: 10px;
}

.caseDescText {
    width: 404px;
    height: 32px;
    font-family: 'Proxima Soft';
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #BDBCC9;
}





$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .casesBlock {
        width: 100%;
        height: auto;
    }

    .casesMain {
        padding: 20px;
    }

    .casesBlock {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 100px;
    }

    .casesLeftBlock,
    .casesRightBlock {
        width: 100%;
        height: 300px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 100px;
        // padding-left: 20px;
    }

    .caseBlock {
        width: 100%;
    }

    .caseTitleText {
        font-size: 25px;
    }

    .caseDescText {
        width: 100%;
        font-size: 14px;
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .casesBlock {
        width: 100%;
        height: auto;
    }

    .casesMain {
        padding: 20px;
    }

    .casesBlock {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 100px;
    }

    .casesLeftBlock,
    .casesRightBlock {
        width: 100%;
        height: 300px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 100px;
        // padding-left: 20px;
    }

    .caseBlock {
        width: 100%;
    }

    .caseTitleText {
        font-size: 25px;
    }

    .caseDescText {
        width: 100%;
        font-size: 14px;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .casesBlock {
        width: 100%;
        height: auto;
    }

    .casesMain {
        padding: 30px;
    }

    .casesBlock {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 100px;
    }

    .casesLeftBlock,
    .casesRightBlock {
        width: 100%;
        height: 300px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        grid-gap: 100px;
        // padding-left: 20px;
    }

    .caseTitle {
        align-items: flex-start;
    }

    .caseIco {
        width: 45px;
        height: 45px;

        img {
            width: 100%;
        }
    }

    .caseBlock {
        width: 100%;
    }

    .caseTitleText {
        font-size: 32px;
        padding-top: 5px;
    }

    .caseDescText {
        width: 100%;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.03em;
    }
}
