.FillAppBtn {
    width: 215px;
    height: 50px;
    padding: 12px 32px 12px 32px;
    border-radius: 70px;
    background: white;
    border: none;

    color: #060040 !important;
    font-family: Proxima Soft;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    letter-spacing: 0.48px;
    
    transition: all 0.6s;
    &:hover {
        cursor: pointer;
        background-color: #a7e2ff;
    }
}




$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .FillAppBtn {
        width: 90%;
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .FillAppBtn {
        width: 90%;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .FillAppBtn {
        width: 350px;
        font-size: 20px;
        height: 70px;
    }
}
