.container {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 10px;
    margin: 100px 0;
}

.GameMain {
    width: 1384px;
    height: 660px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0px 25px;
    // margin-top: 200px;
}

.ItSTitleBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    font-size: 150px;
    -webkit-text-stroke: 1px #e1e1e1;
    line-height: 90px;
    font-family: 'Proxima Soft';
    font-weight: 700;
}

.ItSMainBlock {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.ItSContentBlock {
    width: 40%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.ItSLaptop {
    position: absolute;
    margin-left: -144px;
    height: 660px;
    margin-top: -85px;
}

.ItSDescBlock {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    top: -73px;
    left: 326px;
}

.ItSSescsText {
    font-family: 'Proxima Soft';
    width: 100%;
    text-align: left;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #BDBCC9;
}

.FillBtnWrapper {
    width: 100%;
    height: 110px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.astronautImg {
    height: 600px;
    position: absolute;
    margin-left: -500px;
    margin-top: -100px;
}





$mobile_min_width: 320px;
$mobile_max_width: 480px;
$tablet_min_width: 768px;
$tablet_max_width: 992px;



@media only screen and (min-width: $mobile_min_width) and (max-width: $mobile_max_width) {
    .GameMain {
        width: 100%;
        height: 100%;
        padding: 0;
        justify-content: flex-start;
    }

    .container {
        overflow: hidden;
    }

    .ItSTitleBlock {
        font-size: 75px;
        line-height: 65px;
    }

    .ItSMainBlock {
        height: auto;
        width: 100%;
        margin-top: 140px;
        flex-direction: column;
    }

    .ItSContentBlock {
        width: 100%;
    }

    .ItSLaptop {
        position: absolute;
        margin-left: -128px;
        height: 360px;
        margin-top: 70px;
    }

    .ItSDescBlock {
        left: auto;
        z-index: 100;
    }

    .ItSSescsText {
        font-size: 18px;
        line-height: 15px;
    }

    .FillBtnWrapper {
        position: relative;
        left: 45px;
        top: 50px;
    }

    .astronautImg {
        height: 400px;
        position: relative;
        margin-left: 197px;
        margin-top: -435px;
    }
}

@media only screen and (min-width: $mobile_max_width) and (max-width: $tablet_min_width) {
    .GameMain {
        width: 100%;
        height: 100%;
        padding: 0;
        justify-content: flex-start;
    }

    .container {
        overflow: hidden;
    }

    .ItSTitleBlock {
        font-size: 75px;
        line-height: 65px;
    }

    .ItSMainBlock {
        height: auto;
        width: 100%;
        margin-top: 140px;
        flex-direction: column;
    }

    .ItSContentBlock {
        width: 100%;
    }

    .ItSLaptop {
        position: absolute;
        margin-left: -128px;
        height: 360px;
        margin-top: 70px;
    }

    .ItSDescBlock {
        left: auto;
        z-index: 100;
    }

    .ItSSescsText {
        font-size: 18px;
        line-height: 15px;
    }

    .FillBtnWrapper {
        position: relative;
        left: 45px;
        top: 50px;
    }

    .astronautImg {
        height: 400px;
        position: relative;
        margin-left: 197px;
        margin-top: -435px;
    }
}

@media only screen and (min-width: $tablet_min_width) and (max-width: $tablet_max_width) {
    .GameMain {
        width: 100%;
        height: 100%;
        padding: 0;
        justify-content: flex-start;
    }

    .container {
        overflow: hidden;
    }

    .ItSTitleBlock {
        font-size: 75px;
        line-height: 65px;
    }

    .ItSMainBlock {
        height: auto;
        width: 100%;
        margin-top: 140px;
        flex-direction: column;
    }

    .ItSContentBlock {
        width: 100%;
    }

    .ItSLaptop {
        position: absolute;
        margin-left: -128px;
        height: 360px;
        margin-top: 70px;
    }

    .ItSDescBlock {
        left: auto;
        z-index: 100;
    }

    .ItSSescsText {
        font-size: 18px;
        line-height: 15px;
    }

    .FillBtnWrapper {
        position: relative;
        left: 45px;
        top: 50px;
    }

    .astronautImg {
        height: 400px;
        position: relative;
        margin-left: 197px;
        margin-top: -435px;
    }
}
